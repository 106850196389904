.MuiPaper-root {
  color: #ffffff !important;
  background-color: #2d2d2f !important;
  border-bottom-right-radius: 99px;
}

.MuiListItemIcon-root {
  color: #ffffff !important;
}

.mainItem {
  color: #ffffff;
}

.activeItem,
.activeItem .MuiListItemIcon-root,
.activeItem .otherItem {
  color: #009b4b !important;
}

.otherItem {
  color: #878887;
}

.userNameContainer {
  margin: 1em;
}

.userName {
  float: left;
  color: #ffffff;
  padding: 12px !important;
  max-width: 11em;
}

.closeMenu {
  float: right;
}

.closeMenu .MuiIconButton-root {
  color: #ffffff !important;
}

.switchAccount .MuiInputLabel-root {
  color: white !important;
}

.switchAccount .MuiSelect-icon {
  color: white;
}

.switchAccount .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.switchAccount .MuiInputBase-input {
  border-color: white !important;
  color: white !important;
}
@media (min-width: 600px) {
  .homePageContainer {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
}
