html,
body {
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
}

html {
  background-image: linear-gradient(
    to right top,
    #f6f6fa,
    #f8f8fb,
    #fbfafc,
    #fdfdfe,
    #ffffff
  ) !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url('./assets/images/swirls.png') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
  padding-top: constant(safe-area-inset-top) !important;
  padding-top: env(safe-area-inset-top) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.paymentMethodArea {
  padding: 20px;
  border-radius: 10px;
  background: white;
}

.paymentMethodArea .leftArea {
  float: left;
  width: 20%;
  color: #007639;
  padding-top: 5px;
  text-align: center;
}

.paymentMethodArea .rightarea {
  float: left;
  width: 80%;
}

.paymentMethodArea .MuiFormControl-root {
  margin-top: 20px;
}

.js-sma-modal .swal-footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.js-sma-modal .swal-footer .sma-close {
  background-color: white !important;
  opacity: 0.4;
  border: 0;
}

.js-sma-modal .swal-footer .sma-close:focus {
  box-shadow: none;
}